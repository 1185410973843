import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import {Container, Grid, makeStyles, MenuItem, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SideNavigation from "../components/SideNavigation";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SendIcon from '@material-ui/icons/Send';
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

const Summary = ({summary}) => {
    if (!summary) {
        return null;
    }

    return (
        <Typography component="h2" variant="h4" color="inherit" gutterBottom>
            {summary}
        </Typography>
    );
};

const AdacVertragsanwaltMenuItem = ({category}) => {
    const classes = useStyles();
    if (category !== 'Verkehrsrecht') {
        return null;
    }

    return (
        <MenuItem button className={classes.nested}
                  component="a"
                  href="https://pfeiffer-muenchen.adac-vertragsanwalt.de/"
                  target="_blank">
            <ListItemIcon>
                <SendIcon/>
            </ListItemIcon>
            <ListItemText primary="ADAC-Vertragsanwältin"/>
        </MenuItem>
    )
};

const ComponentName = ({data}) => {

    const {navigationListCurrentRechtsgebiet, currentRechtsgebiet, allCategories: {categories}} = data;
    const {
        title,
        description: {childMarkdownRemark: {html: description}},
    } = currentRechtsgebiet;
    const currentCategory = categories.find(category => category.title === currentRechtsgebiet.category);
    const summary = currentRechtsgebiet.summary?.summary;
    const classes = useStyles();

    return <Layout
        heroHeadline1={currentRechtsgebiet.category}
        heroImage={currentCategory.heroImage.fluid}
    >
        <Container className={classes.cardGrid}>
            <Grid
                spacing={10}// Add it here :)
                container
            >
                <Grid item xs={12} sm={4}>
                    <Divider/>
                    <SideNavigation
                        initialState={true}
                        navItems={navigationListCurrentRechtsgebiet}
                        currentCategory={currentRechtsgebiet.category}
                        categories={categories}
                    >
                        <AdacVertragsanwaltMenuItem
                            category={currentRechtsgebiet.category}>
                        </AdacVertragsanwaltMenuItem>

                    </SideNavigation>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                        {title}
                    </Typography>

                    <Summary summary={summary}/>

                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </Grid>
            </Grid>

        </Container>
    </Layout>
};

export const query = graphql`
query GetRechtsgebietSinglePageContent($currentCategory: String, $currentPageSlug: String) {
  navigationListCurrentRechtsgebiet: allContentfulRechtsgebiet(filter: {category: {eq: $currentCategory}}, sort: {fields: weight, order: ASC}) {
    rechtsgebiete: nodes {
      title
      slug
      category
      weight
    }
  }
  currentRechtsgebiet: contentfulRechtsgebiet(slug: {eq: $currentPageSlug}) {
    title
    category
    summary {
      summary
    }
    description {
      childMarkdownRemark {
        html
      }
    }
  }
  otherCategories: allContentfulKategorie(filter: {title: {ne: $currentCategory}}) {
    categories:nodes {
      title
      slug
    }
  }
  allCategories: allContentfulKategorie {
    categories:nodes {
      title
      slug
      heroImage {
        fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
}
`;

export default ComponentName
